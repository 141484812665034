body {
  background: linear-gradient(180deg, rgba(23, 0, 52, 0) 0%, #170034 100%),
  url('assets/bg.jpg');
  background-color: #170034 !important;
  background-position: center 800px, center top;
  background-repeat: no-repeat;
  background-size: 100% 800px, auto 1500px;

  @media (max-width: 1000px) {
    background: linear-gradient(180deg, rgba(23, 0, 52, 0) 74.36%, #170034 100%),
      url('assets/bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 800px, auto 800px;
    background-position: center 0px, center top;
  }
}

html,
body,
div[id='root'] {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  font-family: 'IBM Plex Mono', sans-serif;
  ::-webkit-datetime-edit-fields-wrapper {
    position: absolute;
    left: 12px;
    top: 35%;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 12px;
    top: 33%;
    background-image: url('../src/assets/images/calendar.svg');
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.slide-enter-done {
  transform: translateX(0);
}

.Toastify__toast {
  color: #fff !important;
  border: 1px solid #3c3d54 !important;
  background: rgba(20, 20, 44, 1) !important;
  border-radius: 20px !important;

  &--success {
  }

  &--error {
    // background: $color-orange;
  }

  &--info {
  }
}
